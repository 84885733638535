import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SPEAKER_CARD_FRAGMENT = `
  fragment speakerCardFragment on Speaker {
    id
    uid
    firstName
    lastName
    name
    prefix
    suffix
    jobTitle
    employerName
    photoFileResource {
      ...fileResourceBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
    _isRecommendedForMe
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
